import AdsComponent from "./AdsComponent";
import "./App.css";

function App() {
  return (
    <>
      <h1>Place To show Google AdSense</h1>
      <AdsComponent dataAdSlot="2694396426" />
    </>
  );
}

export default App;
